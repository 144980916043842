<template>
    <div class="inquiry_popup modal1">
        <div class="popup_box modal_box">
            <div class="popup_title modal_title">
                <h2>문의하기</h2>
                <div class="mo_space_header">
                    <div class="mo_header_box">
                        <a>
                            <img src="/media/images/icon_back_24.svg" @click="$emit('closeQuestion')">
                        </a>
                        <h2 style="margin-bottom:0px;">문의하기</h2>
                        <div></div>
                    </div>
                </div>
            </div>
            <div class="select-box-area">
                <div class="label select-box" v-if="questionCategory === ''" @mouseover.once="clickSelectBox()">일반문의</div>
                <div class="label select-box" v-else @mouseover.once="clickSelectBox()">{{ questionCategory }}</div>
                <div class="select-box-dropDown">
                    <ul>
                        <li class="optionItem">
                            <p>일반문의</p>
                        </li>
                        <li class="optionItem">
                            <p>불편사항</p>
                        </li>
                        <li class="optionItem">
                            <p>기타문의</p>
                        </li>
                        <li class="optionItem">
                            <p>제휴문의</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="input_title">
                <label>
                    <span class="inquiry_text">문의제목</span>
                    <input type="text" maxlength="20" placeholder="문의 제목을 입력해주세요." v-model="questionTitle" />
                    <p>{{ questionTitle.length }}<span>/20</span></p>
                </label>
            </div>
            <div class="input_content">
                <label>
                    <span class="inquiry_text">문의내용</span>

                    <textarea type="text" maxlength="200" placeholder="문의 내용을 입력해주세요." v-model="questionContent"></textarea>
                </label>
                <p>{{ questionContent.length }}<span>/200</span></p>
            </div>
            <div class="popup_bottom modal_bottom_btn">
                <button class="popup_out" @click="$emit('closeQuestion')">취소</button>
                <button :class="{ disabled: disabledInsert === true }" @click="insertQuestion()">확인</button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VueCookies from "vue-cookies";
import axios from "axios";

export default defineComponent({
  data () {
    return {
      questionTitle: "",
      questionContent: "",
      questionCategory: "일반문의",
      selectBoxCheck: false,
      disabledInsert: false
    };
  },
  methods: {
    insertQuestion () {
      this.disabledInsert = true;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };
      var questionWriter = VueCookies.get("userId");
      var questionCategory = this.questionCategory;
      var questionTitle = this.questionTitle;
      var questionContent = this.questionContent;

      axios
        .post("/api/menu/createQuestion", { questionWriter, questionCategory, questionTitle, questionContent }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.disabledInsert = false;
            this.$emit('closeQuestion');
            this.$emit('selectList');
          }
        })
        .catch(err => {
          this.disabledInsert = false;
          console.log(err);
        });
    },
    clickSelectBox () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.questionCategory = item.textContent;
        // label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
}

.modal_bottom_btn button {
  margin-bottom: 0;
}

@media all and (max-width: 500px) {
  .modal_bottom_btn button {
    margin-bottom: 0;
  }
}

@media all and (min-width: 501px) {
  .mo_space_header {
    display: none;
  }
}
</style>
