<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>삭제하시겠습니까?</h3>
            <div class="single-file-btn">
                <p @click="$emit('close')">아니오</p>
                <p id="color-text" @click="deleteQuestion()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  name: 'deleteQuestion',
  props: {
    questionId: String
  },
  methods: {
    deleteQuestion () {
      var questionId = this.questionId;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/menu/deleteQuestion", { questionId }, { headers })
        .then(res => {
          this.$emit('selectList');
          this.$emit('close');
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
