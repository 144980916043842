<template>
    <div class="inquiry">
        <QuestionModal v-if="ShowQuestion" @closeQuestion="ShowQuestion = false" @selectList="getList" />
        <DeleteQuestion v-if="isDeleteQuestion" :questionId="questionId" @close="isDeleteQuestion = false" @selectList="getList" />
        <div class="cs_main">
            <div class="cs_content">
                <div class="inquiry_btn_box">
                    <a class="open_popup p_0" style="cursor: pointer" @click="ShowQuestion = true"> 1:1 문의 </a>
                </div>
                <div class="cs_list_box question" v-if="questionList.length > 0">
                    <ul class="cs_list" :class="{ on: item.answerAt === 'Y' && item.questionAnswerList[0].readYn === 'N' }" style="margin-bottom: 0px" v-for="(item, index) in questionList" :key="index" @click.once="toggleOnOff(item, index, $event)">
                        <li>
                            <p class="content-fontsize" style="margin-bottom: 0px" v-html="item.questionTitle"></p>
                            <span v-if="item.answerAt === 'Y'">답변완료</span>
                            <span class="complet" v-else>답변전</span>
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 7.99935L0 0.666016H12L6 7.99935Z" fill="white" fill-opacity="0.87" />
                            </svg>
                        </li>
                        <li class="sub_cs_list">
                            <ul>
                                <li>
                                    <p v-html="replaceEnter(item.questionContent)" style="margin-bottom: 0px"></p>
                                    <div class="complet_time">
                                        <p style="margin-bottom: 0px">{{ dateTime(item.registDate) }}</p>
                                        <div class="delet_btn">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.66683 2.66536V1.33203H11.3335V2.66536H14.6668V3.9987H13.3335V13.9987C13.3335 14.1755 13.2633 14.3451 13.1382 14.4701C13.0132 14.5951 12.8436 14.6654 12.6668 14.6654H3.3335C3.15669 14.6654 2.98712 14.5951 2.86209 14.4701C2.73707 14.3451 2.66683 14.1755 2.66683 13.9987V3.9987H1.3335V2.66536H4.66683ZM4.00016 3.9987V13.332H12.0002V3.9987H4.00016ZM6.00016 5.9987H7.3335V11.332H6.00016V5.9987ZM8.66683 5.9987H10.0002V11.332H8.66683V5.9987Z"
                                                    fill="white"
                                                />
                                            </svg>
                                            <p style="margin-bottom: 0px; cursor: pointer" @click="deleteQuestion(item.questionId)">삭제하기</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="inquiry_complet" v-for="(answer, index) in item.questionAnswerList" :key="index">
                                    <span class="complet_0">답변</span>
                                    <p v-html="answer.answerContent" class="content-fontsize" style="margin-bottom: 0px"></p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="cs_list_box question" v-else>
                    <ul class="cs_empty" style="padding-left: 0px; margin-bottom: 0px" v-if="loadingComplete">
                        <li class="empty_page">
                            <div><img src="/media/img/Icon_inquary_52.png" alt="" /></div>
                            <p>1:1문의 내용이 없습니다.</p>
                        </li>
                    </ul>
                </div>

                <infinite-loading @infinite="getQuestionList" spinner="waveDots" ref="infiniteLoading">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                    <div slot="error"></div>
                </infinite-loading>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import dayjs from 'dayjs';
import QuestionModal from '@/components/modal/Cs/Question.vue';
import InfiniteLoading from 'vue-infinite-loading';
import $ from 'jquery';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import DeleteQuestion from '../../components/modal/Cs/DeleteQuestion.vue';

export default defineComponent({
  data () {
    return {
      // 리스트
      questionList: [],

      // 등록 팝업
      ShowQuestion: false,

      // 삭제 팝업
      questionId: '',
      isDeleteQuestion: false,

      // 무한 스크롤
      limit: 0,
      loadingComplete: false
    };
  },

  components: {
    QuestionModal,
    InfiniteLoading,
    DeleteQuestion
  },

  updated () {
    this.set();
  },

  methods: {
    getQuestionList ($state) {
      var questionWriter = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      var limit = this.limit;

      axios
        .post('/api/menu/getMyQuestionList', { questionWriter, limit }, { headers })
        .then((res) => {
          if (res.data.result.length / 10 === 0) {
            // 완료
            this.loadingComplete = true;
            $state.complete();
          } else {
            setTimeout(() => {
              var data = res.data.result;
              for (var i in data) {
                if (data[i].questionAnswerList.length > 0) {
                  const { questionAnswerList } = data[i];
                  questionAnswerList.forEach((item) => {
                    item.answerContent = item.answerContent
                      .replaceAll('&lt;', '<')
                      .replaceAll('&gt;', '>')
                      .replaceAll('&amp;', '&')
                      .replaceAll('&#x2F;', '/')
                      .replace(/(?:\r\n|\r|\n)/g, '</br>');
                  });
                }
              }
              this.questionList.push(...data);
              this.limit += 10;
              $state.loaded();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 무한 스크롤 재실행
    getList () {
      this.loadingComplete = false;

      // 리미트값 초기화
      this.limit = 0;

      // 리스트 초기화
      this.questionList = [];

      // 무한 스크롤 재실행
      this.$refs.infiniteLoading.stateChanger.reset();
    },

    replaceEnter (index) {
      return String(index).replace(/(?:\r\n|\r|\n)/g, '</br>');
    },

    set () {
      $('.sub_cs_list').hide();
    },

    dateTime (value) {
      return dayjs(value).format('YYYY.MM.DD \u00a0 | \u00a0 HH:mm');
    },

    toggleOnOff (item, i, e) {
      $(document)
        .off('click')
        .on('click', '.cs_list > li:first-child', function () {
          $(this).siblings().slideToggle(300);
          $(this).children('svg').toggleClass('rotate');
        });

      // 1:1 문의 답변 읽음 처리
      if (item.answerAt === 'Y' && item.questionAnswerList[0].readYn === 'N') {
        e.currentTarget.classList.remove('on');
        const aToken = VueCookies.get('aToken');
        const headers = { 'X-AUTH-TOKEN': aToken };
        const answerId = item.questionAnswerList[0].answerId;

        axios
          .post('/api/menu/updateReadAnswer', { answerId }, { headers })
          .then((res) => {
            if (res.data.resetCd === '0000') {
              this.questionList[i].questionAnswerList[0].readYn = 'Y';
              this.questionList.push();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    deleteQuestion (data) {
      this.questionId = data;
      this.isDeleteQuestion = true;
    }
  }
});
</script>
<style scoped>
.inquiry {
  width: 100%;
}
</style>
<style src="@/assets/css/font.css"></style>
